<template>
  <base-modal @close="onCloseModal" class="user-info-modal">
    <template v-slot:modalBody>
      <div class="user-info-modal__body">
        <h3 class="user-info-modal__header">Сведения о владельце аккаунта</h3>

        <div class="user-info-modal__row">
          <p class="user-info-modal__text">
            Имя
            <span style="color: red">*</span>
          </p>
          <base-text-input
            view="primary"
            placeholder="Имя"
            v-model.trim="userProfileUpdated.name"
          />
        </div>

        <div class="user-info-modal__row">
          <p class="user-info-modal__text">
            Фамилия
            <span style="color: red">*</span>
          </p>
          <base-text-input
            view="primary"
            placeholder="Фамилия"
            v-model.trim="userProfileUpdated.surname"
          />
        </div>

        <!-- <div class="user-info-modal__row">
          <p class="user-info-modal__text">Название компании</p>
          <base-text-input
            view="primary"
            placeholder="Название компании"
            v-model.trim="userProfileUpdated.company"
          />
        </div> -->

        <div class="user-info-modal__row">
          <p class="user-info-modal__text">Должность</p>
          <base-text-input
            view="primary"
            placeholder="Должность"
            v-model.trim="userProfileUpdated.position"
          />
        </div>

        <div class="user-info-modal__row">
          <p class="user-info-modal__text">
            Email
            <span style="color: red">*</span>
          </p>
          <base-text-input
            view="primary"
            placeholder="Email"
            v-model.trim="userProfileUpdated.email"
          />
        </div>

        <div class="user-info-modal__row">
          <p class="user-info-modal__text">Номер телефона</p>
          <base-text-input
            view="primary"
            placeholder="Номер телефона"
            v-model.trim="userProfileUpdated.phone"
          />
        </div>

        <div class="user-info-modal__actions">
          <base-button
            form="square"
            view="secondary"
            :disabled="isDisabled"
            @click="onApply"
          >
            Сохранить
          </base-button>
        </div>
      </div>
    </template>
  </base-modal>
</template>

<script>
import BaseButton from "@/components/Base/BaseButton";
import BaseModal from "@/components/Base/BaseModal";
import BaseTextInput from "@/components/Base/BaseTextInput";

export default {
  components: {
    BaseModal,
    BaseButton,
    BaseTextInput,
  },
  props: {
    userInfo: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      userProfileUpdated: {},
    };
  },
  computed: {
    isDisabled() {
      return (
        !String(this.userProfileUpdated?.name ?? "").length ||
        !String(this.userProfileUpdated?.surname ?? "").length ||
        !String(this.userProfileUpdated?.email ?? "").length
      );
    },
  },
  mounted() {
    this.userProfileUpdated = { ...this.userInfo };
  },
  methods: {
    onCloseModal() {
      this.$emit("onCloseModal");
    },
    onApply() {
      if (!this.isDisabled) {
        this.$emit("onApply", this.userProfileUpdated);
      }
    },
  },
};
</script>

<style lang="scss">
.user-info-modal {
  &__body {
    display: flex;
    flex-direction: column;
    /*width: 360px;*/
    min-height: max-content;
    background: #fff;
    border-radius: 10px;
    box-sizing: border-box;
  }

  &__preloader {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 20px;
  }

  &__header {
    margin-bottom: 20px;
    font-size: 20px;
    font-weight: 500;
    color: #000;

    @media (max-width: 576px) {
      font-size: 14px;
    }
  }

  &__row {
    margin-bottom: 20px;

    &:last-child {
      margin-bottom: 25px;
    }

    &._select {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
  }

  &__text {
    font-size: 12px;
    line-height: 16px;
    margin-bottom: 5px;
  }

  &__actions {
    display: flex;
    align-items: center;
    justify-content: flex-end;

    button {
      margin-right: 10px;

      &:last-child {
        margin-right: 0;
      }
    }
  }

  &__cancel {
    color: #c8cad1;

    &:hover,
    &:active {
      color: #333;
    }
  }
}
</style>
