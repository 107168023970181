<template>
    <base-dropdown
        :padding="0"
        class="user-info-dropdown"
        :is-fixed="true"
        :style-list="dropdownStyles"
        @changeVisible="onClickDropdownMenu"
    >
        <template #preview>
            <BaseButton
                v-show="isShow"
                class="user-info-dropdown__menu-btn"
                view="simple"
                form="oval"
            >
                <div class="user-info-dropdown__icon">
                    <IconVerticalPoints />
                </div>
            </BaseButton>
        </template>
        <template #content>
            <div class="user-info-dropdown__menu">
                <BaseButton
                    v-for="(item, index) in dropdownOptions"
                    :key="index"
                    view="simple"
                    form="oval"
                    class="user-info-dropdown__menu-btn"
                    @click="dropdownOption(item.emit)"
                >
                    {{ item.name }}
                </BaseButton>
            </div>
        </template>
    </base-dropdown>
</template>

<script>
import BaseDropdown from "@/components/Base/BaseDropdown";
import BaseButton from "@/components/Base/BaseButton";
import IconVerticalPoints from "@/components/Icons/IconVerticalPoints";

export default {
    name: "UserInfoDropdown",
    components: {
        BaseDropdown,
        BaseButton,
        IconVerticalPoints,
    },
    /**
     * Входные данные компонента
     * @property {Boolean} isShow - Флаг показать/скрыть дропдаун
     * @property {Array}  dropdownOptions - Список меню
     */
    props: {
        dropdownOptions: {
            type: Array,
            default: () => [],
        },
        isShow: {
            type: Boolean,
            default: true,
        },
    },

    /**
     * Входные данные компонента
     * @property {Boolean} isActiveDropdown - Флаг активности кнопки preview дропдауна
     */
    data: () => ({
        isActiveDropdown: false,
        dropdownStyles: {
            height: "min-content",
            "max-height": "none",
        },
    }),

    methods: {
        /**
         * Активировать выделение на кнопке меню
         * @param {Boolean} isShow - Флаг активности кнопки preview dropdown
         */
        onClickDropdownMenu(isShow) {
            this.isActiveDropdown = isShow;
        },

        /**
         * Клик по пункту меню
         * @param {string} methodName - название функции
         */
        dropdownOption(methodName) {
            this.$emit("dropdownOption", methodName);
        },
    },
};
</script>

<style lang="scss" scoped>
.user-info-dropdown::v-deep .dropdown {
    min-width: auto;
    height: min-content;

    justify-content: flex-start;
    align-items: flex-start;
    flex-wrap: nowrap;

    position: absolute;
    left: 100px;

    padding: 0;
}

.user-info-dropdown::v-deep .dropdown__triangle {
    right: 2px !important;
}

.user-info-dropdown {
    &__menu {
        position: relative;
        background: #fff;
        border-radius: 6px;
        overflow: hidden;
        z-index: 5;
        pointer-events: auto;
        padding: 10px;
    }

    &__icon {
        width: 30px;
        height: 30px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 5px;
        transition: all ease 0.2s;

        &:hover {
            background: #f5f5f5;
        }
    }
}

.user-info-dropdown__menu-btn {
    font-size: 14px;
    font-weight: 400;
    line-height: 18px;
    color: #131314;

    margin-bottom: 8px;

    &:last-child {
        margin-bottom: 0;
    }
}
.user-info-dropdown__menu-item-btn {
    height: 24px;

    &:not(:last-child) {
        margin-bottom: 8px;
    }
}
</style>
