<template>
    <div class="user-profile-page">
        <div class="user__info" style="min-width: 260px">
            <UseInfoDropdown
                :dropdown-options="dropdownUserInfoOptions"
                @dropdownOption="onDropdownOption"
                class="user__info--control"
            />

            <div class="user__info--top">
                <div class="user__info--avatar">
                    <img
                        v-if="userProfileRows.avatar"
                        :src="userProfileRows.avatar"
                        alt=""
                    />
                    <IconAnonym v-else />
                </div>
                <!-- <BaseDropZone
                    :class="['drop-area', { active: file }]"
                    @files-dropped="addFiles"
                >
                    <div class="user__info--avatar">
                        <label for="file-input" class="file-label">
                            <img
                                v-if="userProfileRows.avatar"
                                :src="userProfileRows.avatar"
                                alt=""
                            />
                            <IconAnonym v-else />
                            <input
                                type="file"
                                id="file-input"
                                @change="onFileInputChange"
                            />
                        </label>
                    </div>
                </BaseDropZone> -->

                <div class="user__info--row">
                    <div class="user__info--row-title">
                        {{ userProfileRows.name || "-" }}
                        {{ userProfileRows.surname || "-" }}
                    </div>
                </div>

                <div class="user__info--row">
                    <div class="user__info--row-position">
                        {{ userProfileRows.position || "-" }}
                    </div>
                </div>

                <!-- <div class="user__info--row">
                  <div class="user__info--row-company">
                    {{ userProfileRows.company || "-" }}
                  </div>
                </div> -->
            </div>

            <div class="user__info--bottom">
                <div class="user__info--contacts">
                    <div class="user__info--contacts-label">Email</div>

                    <div class="user__info--contacts-text">
                        {{ userProfileRows.email || "-" }}
                    </div>
                </div>

                <div class="user__info--contacts">
                    <div class="user__info--contacts-label">Номер телефона</div>

                    <div class="user__info--contacts-text">
                        {{ userProfileRows.phone || "-" }}
                    </div>
                </div>
            </div>
        </div>
        <div class="user__info" style="min-width: 400px">
            <UseInfoDropdown
                :dropdown-options="dropdownUserEntitiesOptions"
                @dropdownOption="onDropdownOption"
                class="user__info--control"
            />

            <div class="user__info--title">Реквизиты компании</div>

            <div class="user__info--contacts">
                <div class="user__info--contacts-label">ИНН</div>

                <div class="user__info--contacts-text">
                    {{ userEntitiesRows.inn || "-" }}
                </div>
            </div>

            <div class="user__info--contacts">
                <div class="user__info--contacts-label">
                    Полное наименование компании
                </div>

                <div class="user__info--contacts-text">
                    {{ userEntitiesRows.name || "-" }}
                </div>
            </div>

            <div style="display: flex; justify-content: space-between">
                <div class="user__info--contacts">
                    <div class="user__info--contacts-label">КПП</div>

                    <div class="user__info--contacts-text">
                        {{ userEntitiesRows.kpp || "-" }}
                    </div>
                </div>
                <div
                    v-if="!!userEntitiesRows.ogrn"
                    class="user__info--contacts"
                >
                    <div class="user__info--contacts-label">ОГРН/ОГРНИП</div>

                    <div class="user__info--contacts-text">
                        {{ userEntitiesRows.ogrn || "-" }}
                    </div>
                </div>
            </div>

            <div class="user__info--contacts">
                <div class="user__info--contacts-label">Юридический адрес</div>

                <div class="user__info--contacts-text">
                    {{ userEntitiesRows.address || "-" }}
                </div>
            </div>

            <div class="user__info--contacts">
                <div class="user__info--contacts-label">Фактический адрес</div>

                <div class="user__info--contacts-text">
                    {{ userEntitiesRows.address || "-" }}
                </div>
            </div>

            <div class="user__info--contacts">
                <div class="user__info--contacts-label">Бренд</div>

                <div class="user__info--contacts-text">
                    {{ userEntitiesRows.brand || "-" }}
                </div>
            </div>
        </div>

        <div class="user__info" style="min-width: 240px">
            <UseInfoDropdown
                :dropdown-options="dropdownUserDealOptions"
                @dropdownOption="onDropdownOption"
                class="user__info--control"
            />

            <div class="user__info--title">Договор</div>

            <div class="user__info--contacts">
                <div class="user__info--contacts-label">Номер договора</div>

                <div class="user__info--contacts-text">
                    {{ userDealRows.contract_number || "-" }}
                </div>
            </div>
        </div>

        <UpdateEntitiesModal
            v-if="isUpdateUserEntitiesModal"
            :userEntities="userEntitiesRows"
            @onCloseModal="isUpdateUserEntitiesModal = false"
            @onApply="onUserEntitiesUpdate"
        />
        <UpdateProfileModal
            v-if="isUpdateUserProfileModal"
            :userInfo="userProfileRows"
            @onCloseModal="isUpdateUserProfileModal = false"
            @onApply="onUserProfileUpdate"
        />
        <UpdateDealModal
            v-if="isUpdateUserDealModal"
            :userDeal="userDealRows"
            @onCloseModal="isUpdateUserDealModal = false"
            @onApply="onUserDealUpdate"
        />
    </div>
</template>

<script>
import ServiceAuth from "@/services/ServicesAuth/ServiceAuth";
import ServiceUser from "@/services/ServicesAuth/ServiceUser";
import IconAnonym from "@/components/Icons/IconAnonym.vue";
import UseInfoDropdown from "../components/MapV2/components/UserProfile/UserInfoDropdown";
import UpdateEntitiesModal from "../components/MapV2/components/UserProfile/UpdateEntitiesModal";
import UpdateProfileModal from "../components/MapV2/components/UserProfile/UpdateProfileModal";
import UpdateDealModal from "../components/MapV2/components/UserProfile/UpdateDealModal";
import { dropdownUserEntitiesOptionsScheme, dropdownUserDealOptionsScheme, dropdownUserInfoOptionsScheme } from "../schemes/SchemeUserProfile";
import {mapMutations} from "vuex";
// import BaseDropZone from "@/components/Base/BaseDropZone";
// import ServiceApi from '../services/ServiceApi';

export default {
    name: "PageUserProfile",
    components: {
        IconAnonym,
        UseInfoDropdown,
        UpdateEntitiesModal,
        UpdateProfileModal,
        UpdateDealModal,
        // BaseDropZone,
    },

    data() {
        return {
            isLoading: true,
            isUpdateUserProfileModal: false,
            isUpdateUserEntitiesModal: false,
            isUpdateUserDealModal: false,
            file: null,
            profile: {},
            userProfileRows: {},
            userEntitiesRows: {},
            userDealRows: {},
            dropdownUserInfoOptions: dropdownUserInfoOptionsScheme,
            dropdownUserEntitiesOptions: dropdownUserEntitiesOptionsScheme,
            dropdownUserDealOptions: dropdownUserDealOptionsScheme,
        };
    },

    async beforeCreate() {
        this.isLoading = true;

        await ServiceUser.getUserEntities((userEntities) => {
            this.userEntitiesRows = userEntities;
            this.userDealRows = {
                contract_number: String(userEntities?.contract_number ?? ""),
            };
        });

        await ServiceUser.getUserProfile((userInfo) => {
            this.setUserProfile(userInfo);
            this.userProfileRows = userInfo;
        });

        this.isLoading = false;
    },

    methods: {
        ...mapMutations(['setUserProfile']),
        onDropdownOption(val) {
            switch (val) {
                case "onChangeUserInfo":
                    this.isUpdateUserProfileModal = true;
                    break;
                case "OnLogout":
                    this.logout();
                    break;
                case "onChangeUserEntities":
                    this.isUpdateUserEntitiesModal = true;
                    break;
                case "onChangeUserDeal":
                    this.isUpdateUserDealModal = true;
                    break;
            }
        },

        // onFileInputChange(e) {
        //   this.addFiles(e.target.files[0]);
        // },

        // addFiles(newFiles) {
        //   this.file = newFiles;
        //   this.userProfileRows.avatar = this.file;
        // },

        // removeFile() {
        //   this.file = null;
        // },

        async onUserEntitiesUpdate(userEntitiesUpdated) {
            await ServiceUser.updateUserEntities(
                userEntitiesUpdated,
                () => (this.userEntitiesRows = userEntitiesUpdated),
                (error) => alert(error)
            );

            this.isUpdateUserEntitiesModal = false;
        },

        async onUserProfileUpdate(userProfileUpdated) {
            await ServiceUser.updateUserProfile(
                userProfileUpdated,
                () => (this.userProfileRows = userProfileUpdated)
            );
            this.isUpdateUserProfileModal = false;
        },

        async onUserDealUpdate(userDealUpdated) {
            await ServiceUser.updateUserEntities(
                { ...this.userEntitiesRows, ...userDealUpdated },
                () => (this.userDealRows = { ...userDealUpdated })
            );
            this.isUpdateUserDealModal = false;
        },

        async logout() {
            await ServiceAuth.logout();
        },
    },
};
</script>

<style lang="scss" scoped>
.drop-area {
    display: flex;
    align-items: center;
    justify-content: center;

    width: min-content;
    margin: auto;
    margin-bottom: 15px;
    border-radius: 50%;
    cursor: pointer;
    border: 1px dashed transparent;

    transition: all 0.2s ease;

    &[data-active="true"] {
        opacity: 0.5;
    }
}

.file-label {
    font-size: 36px;
    cursor: pointer;
    display: block;

    span {
        display: block;
    }

    input[type="file"]:not(:focus-visible) {
        // Visually Hidden Styles taken from Bootstrap 5
        position: absolute !important;
        width: 1px !important;
        height: 1px !important;
        padding: 0 !important;
        margin: -1px !important;
        overflow: hidden !important;
        clip: rect(0, 0, 0, 0) !important;
        white-space: nowrap !important;
        border: 0 !important;
    }
}

.user__info {
    position: relative;
    vertical-align: top;
    display: inline-block;
    width: max-content;
    padding: 30px;
    margin-bottom: 30px;
    margin-right: 30px;
    border-radius: 15px;
    background: #fff;

    &--contacts {
        margin-bottom: 20px;

        &:last-child {
            margin-bottom: 0;
        }

        &-label {
            margin-bottom: 5px;
            font-size: 12px;
            font-weight: 400;
            line-height: 14px;
            color: #979ba4;
        }

        &-text {
            position: relative;
            width: 100%;
            height: 100%;
            font-size: 14px;
            font-weight: 400;
            line-height: 18px;
            color: #131314;
        }

        &-input {
            font-size: 14px;
            font-weight: 400;
            line-height: 18px;
            color: #131314;
        }

        &-preloader {
            position: absolute;
            inset: 0;
        }
    }

    &--row {
        &-title {
            font-size: 16px;
            font-weight: 600;
            line-height: 19px;
            color: #131314;
        }

        &-position {
            margin-top: 5px;
            font-size: 12px;
            font-weight: 400;
            line-height: 14px;
            color: #979ba4;
        }

        &-company {
            margin-top: 15px;
            font-size: 14px;
            font-weight: 400;
            line-height: 18px;
            color: #131314;
        }
    }

    &--deal {
        position: absolute;
        top: 30px;
        right: 30px;
    }

    &--control {
        position: absolute;
        right: 5px;
        top: 10px;
    }

    &--top {
        text-align: center;
        // padding-bottom: 20px;
        margin-bottom: 20px;
        border-bottom: 1px solid #f3f6f9;
    }

    &--avatar {
        width: 80px;
        height: 80px;
        border-radius: 50%;
        margin: auto;
        margin-bottom: 15px;

        display: flex;
        align-items: center;
        justify-content: center;

        background: #f3f6f9;
    }

    &--title {
        font-weight: 600;
        font-size: 16px;
        line-height: 19px;
        color: #131314;
        margin-bottom: 20px;
    }
}
</style>
