<template>
  <base-modal @close="onCloseModal" class="user-info-modal">
    <template v-slot:modalBody>
      <div class="user-info-modal__body">
        <h3 class="user-info-modal__header">Сведения об организации</h3>

        <div class="user-info-modal__row">
          <p class="user-info-modal__text">
            ИНН
            <span style="color: red">*</span>
          </p>
          <base-text-input
            view="primary"
            placeholder="ИНН"
            v-model.trim="userEntitiesUpdated.inn"
          />
        </div>

        <div class="user-info-modal__row">
          <p class="user-info-modal__text">
            Полное наименование компании
            <span style="color: red">*</span>
          </p>
          <base-text-input
            view="primary"
            placeholder="Полное наименование компании"
            v-model.trim="userEntitiesUpdated.name"
          />
        </div>

        <div style="display: flex; justify-content: space-between">
          <div class="user-info-modal__row">
            <p class="user-info-modal__text">КПП</p>
            <base-text-input
              view="primary"
              placeholder="КПП"
              v-model.trim="userEntitiesUpdated.kpp"
            />
          </div>

          <div class="user-info-modal__row">
            <p class="user-info-modal__text">
                ОГРН/ОГРНИП
                <span style="color: red">*</span>
            </p>
            <base-text-input
              view="primary"
              placeholder="ОГРН/ОГРНИП"
              v-model.trim="userEntitiesUpdated.ogrn"
            />
          </div>
        </div>

        <div class="user-info-modal__row">
          <p class="user-info-modal__text">
            Юридический адрес
            <span style="color: red">*</span>
          </p>
          <base-text-input
            view="primary"
            placeholder="Юридический адрес"
            v-model.trim="userEntitiesUpdated.address"
          />
        </div>

        <div class="user-info-modal__row">
          <p class="user-info-modal__text">
            Бренд
          </p>
          <base-text-input
            view="primary"
            placeholder="Бренд"
            v-model.trim="userEntitiesUpdated.brand"
          />
        </div>

        <div class="poi-modal__actions">
          <base-button form="square" view="secondary" :disabled="isDisabled" @click="onApply">
            Сохранить
          </base-button>
        </div>
      </div>
    </template>
  </base-modal>
</template>

  <script>
import BaseButton from "@/components/Base/BaseButton";
import BaseModal from "@/components/Base/BaseModal";
import BaseTextInput from "@/components/Base/BaseTextInput";

export default {
  components: {
    BaseModal,
    BaseButton,
    BaseTextInput,
  },
  props: {
    userEntities: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      userEntitiesUpdated: {},
    };
  },
  computed: {
    isDisabled() {
      return (
        !String(this.userEntitiesUpdated?.name ?? "").length ||
        !String(this.userEntitiesUpdated?.inn ?? "").length ||
        !String(this.userEntitiesUpdated?.address ?? "").length ||
        !String(this.userEntitiesUpdated?.ogrn ?? "").length
      );
    },
  },
  mounted() {
    this.userEntitiesUpdated = { ...this.userEntities };
  },
  methods: {
    onCloseModal() {
      this.$emit("onCloseModal");
    },
    onApply() {
      if (!this.isDisabled) {
        this.$emit("onApply", this.userEntitiesUpdated);
      }
    },
  },
};
</script>

  <style lang="scss">
.user-info-modal {
  &__body {
    display: flex;
    flex-direction: column;
    /*width: 360px;*/
    min-height: max-content;
    background: #fff;
    border-radius: 10px;
    box-sizing: border-box;
  }

  &__preloader {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 20px;
  }

  &__header {
    margin-bottom: 20px;
    font-size: 20px;
    font-weight: 500;
    color: #000;

    @media (max-width: 576px) {
      font-size: 14px;
    }
  }

  &__row {
    margin-bottom: 20px;

    &:last-child {
      margin-bottom: 25px;
    }

    &._select {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
  }

  &__text {
    font-size: 12px;
    line-height: 16px;
    margin-bottom: 5px;
  }

  &__actions {
    display: flex;
    align-items: center;
    justify-content: flex-end;

    button {
      margin-right: 10px;

      &:last-child {
        margin-right: 0;
      }
    }
  }

  &__cancel {
    color: #c8cad1;

    &:hover,
    &:active {
      color: #333;
    }
  }
}
</style>
